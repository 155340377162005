/* eslint-disable no-unused-vars */
import React from 'react';
import { IMAGE_BASE_URL } from '../confing';
import './collaborateur.css';

function Collaborateur({ collaborateur }) {
  return (
    <a href={collaborateur.link} target="_blank">
      <div
        className="soutien"
        alt={`image de notre collaborateur ${collaborateur.name}`}
        style={{
          backgroundImage: `url(${IMAGE_BASE_URL}/images/collaborateurs/${collaborateur.background})`,
        }}
      />
    </a>
  );
}

export default Collaborateur;
