/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-undef */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../components/Admin.css';
import AdminColumnItems from '../components/AdminColumnItems';
import DescriptionAdmin from '../components/DescriptionAdmin';
import { useGetData } from '../hooks/useGetData';
import { isTokenExpired } from '../Utils/Helpers';

function Admin() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [view, setView] = useState(localStorage.getItem('view') || 'feed');
  const [descriptionData, setDescriptionData] = useState('');

  const background = 'images/fonds/Home.jpg';

  const handleLogOut = () => {
    document.cookie = 'refresh_token=; Max-Age=0; path=/;';
    localStorage.removeItem('user');
    localStorage.removeItem('isAuthenticated');
    navigate('/Conexion');
  };

  const { data: spectacles } = useGetData({ column: 'spectacles' });
  const { data: events } = useGetData({ column: 'agenda' });
  const { data: membres } = useGetData({ column: 'membres' });
  const { data: collaborateurs } = useGetData({ column: 'collaborateurs' });
  const {
    data: [presentation],
  } = useGetData({ column: 'presentation' });
  useEffect(() => {
    const token = localStorage.getItem('user');
    const validUser = isTokenExpired(token);
    if (!validUser) {
      handleLogOut();
    } else {
      setUser(validUser);
    }
  }, []);

  useEffect(() => {
    if (presentation) {
      setDescriptionData(presentation);
    }
  }, [presentation]);

  const handleShow = (view) => {
    setView(view);
    localStorage.setItem('view', view);
  };

  if (!user) {
    return null;
  }

  return (
    <main className="container_admin" background={background}>
      <h1>Admin</h1>
      <div className="user_banner">
        <img src={user.picture} alt="User Avatar" />
        <p>{user.name}</p>
        <button onClick={handleLogOut}>
          <LogoutOutlinedIcon />
        </button>
      </div>
      <div className="ln_bar_separator"></div>
      <section style={{ width: '100%' }}>
        <div
          className="admin_toogle_view_btns"
          style={{ display: 'flex', gap: '30px' }}
        >
          <h3 onClick={() => handleShow('spectacles')}>Spectacles</h3>
          <h3 onClick={() => handleShow('agenda')}>Agenda</h3>
          <h3 onClick={() => handleShow('membres')}>Équipe</h3>
          <h3 onClick={() => handleShow('collaborateurs')}>Collaborateurs</h3>
          <h3 onClick={() => handleShow('presentation')}>Description</h3>
        </div>
        {view === 'spectacles' && (
          <AdminColumnItems title={'spectacles'} items={spectacles} />
        )}
        {view === 'agenda' && (
          <AdminColumnItems title={'agenda'} items={events} />
        )}
        {view === 'membres' && (
          <AdminColumnItems title={'membres'} items={membres} />
        )}
        {view === 'collaborateurs' && (
          <AdminColumnItems title={'collaborateurs'} items={collaborateurs} />
        )}
        {presentation !== null && view === 'presentation' && (
          <DescriptionAdmin data={descriptionData} />
        )}
      </section>
    </main>
  );
}

export default Admin;
