/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainLayaut from '../components/MainLayaut';
import '../components/ContactForm.css';
import { sendEmail } from '../services/appServices';
import TextArea from '../components/TextArea';
import Backdrop from '../components/Backdrop';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { motion } from 'framer-motion';

function Contact () {
  const [t] = useTranslation('pages');
  const background = 'images/fonds/Contact.webp';
  const textAreaRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [messagesSubmit, setMessagesSubmit] = useState('');
  const itemVariants = {
    hidden: { y: '100vh', opacity: 0 },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.08,
        type: 'spring',
        damping: 25,
        stiffness: 500
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let message = [];
    let formValid = true;

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      subject: e.target.subject.value,
      message: textAreaRef.current.value
    };
    for (const [key, value] of Object.entries(formData)) {
      if (value === '') {
        message.push('\nRamplizez touts les champs');
        formValid = false;
        break;
      }
    }
    if (formData.message.length > 300) {
      message.push(' \n300 caracters max pour le message');
      formValid = false;
      textAreaRef.current.classList.add('invalid_field');
    } else {
      textAreaRef.current.classList.remove('invalid_field');
    }
    if (formValid) {
      const response = await sendEmail(formData);
      if (response.ok) {
        message = t('/Contact.form.submit.ok');
        setMessagesSubmit(message);
        setModalOpen(true);
      } else {
        message = t('/Contact.form.submit.error');
        setMessagesSubmit(message);
        setModalOpen(true);
      }
    } else {
      setModalOpen(true);
      setMessagesSubmit(message);
    }
  };

  return (
    <MainLayaut
      background={background}
      meta={{ title: 'Acquaforte Contact', description: "Contactez-nous pour découvrir l'univers théâtral de Montpellier avec Acquaforte Theatre. Nous sommes là pour répondre à vos questions et vous fournir toutes les informations dont vous avez besoin sur nos spectacles et événements. N'hésitez pas à nous contacter pour une expérience théâtrale inoubliable." }}
    >
      <div className='container_form'>
        <motion.form
          className="form_contact"
          onSubmit={handleSubmit}
          variants={itemVariants}
          initial='hidden'
          animate='visible'
        >
          <input name="name" type="text" placeholder={t('/Contact.form.input-name-placeholder')} />
					<input name="email" type="email" placeholder={t('/Contact.form.input-mail-placeholder')} />
					<input name="subject" type="text" placeholder={t('/Contact.form.input-subject-placeholder')} />
          <TextArea ref={textAreaRef} placeholder={t('/Contact.form.textarea-placeholder')} minHeight={'200px'} />
					<button style={{ width: '100%' }} type="submit">{t('/Contact.form.button-submit')}</button>
				</motion.form>
      </div>
      {
        modalOpen && (
          <Backdrop onClick={() => setModalOpen(false)}>
            <div className='contact_alert'>
                <div className='ln_separator'/>
              <span onClick={() => setModalOpen(false)}>
                <CloseOutlinedIcon />
              </span>
              {
                messagesSubmit && (
                  messagesSubmit.map(message => <p>{message}</p>)
                )
              }
            </div>
          </Backdrop>
        )
      }
    </MainLayaut>
  );
}

export default Contact;
