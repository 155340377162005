/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import SpectacleViewDescription from '../components/SpectacleViewDescription';
import SpectacleViewPhotos from '../components/SpectacleViewPhotos';
import SpectacleViewVideo from '../components/SpectacleViewVideo';
import { capitalize } from '../Utils/Helpers';

import '../components/SpectacleView.css';

function SpectacleBackdrop({ spectacle, handleBackdrop }) {
  const [view, setView] = useState('description');
  const [videoValid, setVideoValid] = useState(false);
  const [t] = useTranslation('pages');
  const [searchParams, setSearchParams] = useSearchParams();

  /* const handleVideo = (video) => {
    const redex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|v\/|embed\/|user\/\S+~)\S+$/;
    if (redex.test(video)) {
      setVideoValid(true);
    }
    console.log(redex.test(video));
  }; */
  const redex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|v\/|embed\/|user\/\S+~)\S+$/;

  const handleView = (view) => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams.toString());
      params.set('view', view);
      return params;
    });
  };
  useEffect(() => {
    if (searchParams.has('view')) {
      setView(searchParams.get('view'));
    }
  }, [searchParams]);

  return !spectacle ? (
    <p> Loading... </p>
  ) : (
    <section className="spectacle_backdrop_open">
      <section className="head_container">
        <h1>{capitalize(spectacle.title)}</h1>
        <div className="btns_spectacle">
          <p
            style={{
              color:
                view === 'description'
                  ? 'var(--active-color)'
                  : 'var(--back-text-color)',
            }}
            onClick={() => handleView('description')}
            aria-label="Bouton description spectacle"
          >
            {t('/SpectacleView.btn-description')}
          </p>
          <p
            style={{
              color:
                view === 'photos'
                  ? 'var(--active-color)'
                  : 'var(--back-text-color)',
            }}
            onClick={() => handleView('photos')}
            aria-label="Bouton photos spectacle"
          >
            {t('/SpectacleView.btn-photos')}
          </p>
          {spectacle.video_link && redex.test(spectacle.video_link) && (
            <p
              style={{
                color:
                  view === 'video'
                    ? 'var(--active-color)'
                    : 'var(--back-text-color)',
              }}
              onClick={() => handleView('video')}
              aria-label="Bouton videos spectacle"
            >
              {t('/SpectacleView.btn-video')}
            </p>
          )}
          <Link to={'../Contact'} aria-label="Bouton page contact">
            {t('/SpectacleView.btn-contact')}
          </Link>
          <Link
            onClick={handleBackdrop}
            aria-label="Bouton retour page spectacles"
          >
            {t('/SpectacleView.btn-retour')}
          </Link>
        </div>
      </section>
      <div className="ln_bar_separator"></div>
      {view === 'description' && (
        <SpectacleViewDescription spectacle={spectacle} />
      )}
      {view === 'photos' && <SpectacleViewPhotos photos={spectacle.images} />}
      {view === 'video' && spectacle.video_link && (
        <SpectacleViewVideo video={spectacle.video_link} />
      )}
    </section>
  );
}

export default SpectacleBackdrop;
