/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useGetData } from '../hooks/useGetData';
import { useTranslation } from 'react-i18next';
import EventCard from '../components/EventCard';
import Backdrop from '../components/Backdrop';
import SpectacleBackdrop from '../components/SpectacleBackdrop';
import { useSearchParams } from 'react-router-dom';
import MainLayaut from '../components/MainLayaut';

function Agenda () {
  const background = 'images/fonds/Agenda.webp';
  const { data: events } = useGetData({ column: 'agenda' });
  const { data: spectacles } = useGetData({ column: 'spectacles' });
  const [spectacleOpen, setSpectacleOpen] = useState(false);
  const [spectacleSelected, setSpectacleSelected] = useState(null);
  const [t] = useTranslation('pages');
  const [searchParams, setSearchParams] = useSearchParams({
    id: '',
    open: false,
    title: '',
    view: 'description'
  });
  const handleBackdrop = () => {
    // this function is empty to cancel the backdrop's behaviour and only closed it with the url manipulation
  };
  useEffect(() => {
    const id = searchParams.get('id');
    const isOpen = searchParams.get('open') === 'true';
    if (id && spectacles) {
      const spectacleToShow = spectacles.find(spectacle => `${spectacle.id}` === id);
      setSpectacleSelected(spectacleToShow);
    }
    setSpectacleOpen(isOpen);
  }, [searchParams, spectacles]);

  const handleOpenSpectacle = (event) => {
    setSearchParams({
      id: event.id_spectacle,
      event: event.title,
      open: true,
      view: 'description'
    });
  };
  return (
    <MainLayaut
      background={background}
      meta={{ title: 'Acquaforte Agenda', description: 'Découvrez les prochains spectacles passionnants programmés par notre compagnie. Trouvez votre prochaine expérience culturelle ici.' }}>
      <section className='container_cards'>
        {
          (events && events.length) > 0
            ? (
                events.map((event, index) => (
                  <EventCard
                    event={event}
                    key={event.id}
                    delay={index}
                    onClick={() => {
                      handleOpenSpectacle(event);
                    }}
                  />
                ))
              )
            : (
                <p className='no_events_message'>{ t('/Agenda.no-events-message') }</p>
              )
        }
        {
          (spectacleOpen && spectacleSelected) && (
            <Backdrop onClick={handleBackdrop} >
              <SpectacleBackdrop spectacle={spectacleSelected} handleBackdrop={handleBackdrop} />
            </Backdrop>
          )
        }
      </section>
    </MainLayaut>
  );
}

export default Agenda;
