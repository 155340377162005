// const host = 'http://localhost:3307';
import { API_BASE_URL } from '../confing';
const host = API_BASE_URL;

export const sendEmail = async (formData) => {
  const response = await fetch(`${host}/api/sendEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
  const result = await response.json();
  return result;
};
export const queryUser = async ({ email }) => {
  const response = await fetch(`${host}/api/logIn/${email}`);
  const data = await response.json();
  return data;
};
export const translateText = async ({ text, languageTarget }) => {
  const data = { text, languageTarget };
  if (text === '') {
    return { error: 'Il faut un texte pour le traduire!' };
  }
  const response = await fetch(`${host}/api/translateText`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const translation = await response.json();
  return translation;
};
export const updateDescription = async (descriptionData) => {
  const response = await fetch(`${host}/api/updateDescription`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Especifica que el contenido es JSON
    },
    body: JSON.stringify(descriptionData), // Convierte descriptionData a JSON
  });
  const result = await response.json();
  return result;
};
export const deleteItem = async ({ column, id, paths }) => {
  const itemData = { column, id, paths };
  const response = await fetch(`${host}/api/delete/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(itemData),
  });
  const result = await response.json();
  return result;
};
export const updateEvent = async ({ newData }) => {
  const response = await fetch(`${host}/api/updateEvent`, {
    method: 'POST',
    body: newData,
  });
  const result = await response.json();
  return result;
};
export const updateMembreEquipe = async (formData) => {
  const response = await fetch(`${host}/api/updateMembreEquipe`, {
    method: 'POST',
    body: formData,
  });
  const result = await response.json();
  return result;
};
export const updateCollaborateur = async (formData) => {
  const response = await fetch(`${host}/api/updateCollaborateur`, {
    method: 'POST',
    body: formData,
  });
  const result = await response.json();
  return result;
};
export const updateSpectacle = async (formData) => {
  const response = await fetch(`${host}/api/updateSpectacle`, {
    method: 'POST',
    body: formData,
  });
  const result = await response.json();
  return result;
};
export const updatePost = async (formData) => {
  const response = await fetch(`${host}/api/updatePost`, {
    method: 'POST',
    body: formData,
  });
  const result = await response.json();
  return result;
};
export const insertCollaborateur = async (formData) => {
  const response = await fetch(`${host}/api/insertCollaborateur`, {
    method: 'POST',
    body: formData,
  });
  const result = await response.json();
  return result;
};
export const insertMembreEquipe = async (membreData) => {
  const response = await fetch(`${host}/api/insertMembreEquipe`, {
    method: 'POST',
    body: membreData,
  });
  const result = await response.json();
  return result;
};
export const insertSpectacle = async (spectacleData) => {
  const response = await fetch(`${host}/api/insertSpectacle`, {
    method: 'POST',
    body: spectacleData,
  });
  const result = await response.json();
  return result;
};
export const insertEvent = async (eventData) => {
  const response = await fetch(`${host}/api/insertEvent`, {
    method: 'POST',
    body: eventData,
  });
  const data = await response.json();
  return data;
};
export const insertPost = async (postData) => {
  const response = await fetch(`${host}/api/insertPost`, {
    method: 'POST',
    body: postData,
  });
  const data = await response.json();
  return data;
};
export const queryData = async ({ column }) => {
  const response = await fetch(`${host}/api/getData/${column}`);
  if (response.ok) {
    const data = await response.json();
    parseData(data);
    return { data, ok: true };
  } else {
    console.log(error);
    return { error: '500' };
  }
};
function parseData(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string' && isJSONString(obj[key])) {
      obj[key] = JSON.parse(obj[key]);
    } else if (typeof obj[key] === 'object') {
      parseData(obj[key]);
    }
  }
}
function isJSONString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
