/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import TextArea from './TextArea';
import { useDropzone } from 'react-dropzone';
import { insertMembreEquipe, translateText, updateMembreEquipe } from '../services/appServices';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function AdminFormInsertMembre ({ itemToUpdate, handleBackdrop }) {
  // console.log(itemToUpdate.contact);
  const textAreaRef = useRef(null);
  const [currentLanguage, setCurrentLanguage] = useState('fr');
  const [textAreaValues, setTextAreaValues] = useState({ fr: '', esp: '', en: '' });
  const [infoDuContact, setInfoDuContact] = useState({ icons: [], links: [] });
  const [prevImage, setPrevImage] = useState('');
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone();

  useEffect(() => {
    if (itemToUpdate) {
      const descriptionValues = {
        fr: itemToUpdate.description.fr,
        esp: itemToUpdate.description.esp,
        en: itemToUpdate.description.en
      };
      setTextAreaValues(descriptionValues);
      textAreaRef.current.value = itemToUpdate.description[currentLanguage];
      if (itemToUpdate.contact && itemToUpdate.contact.links) {
        setInfoDuContact(itemToUpdate.contact); // agregar las infos existentes
      }
      setPrevImage(itemToUpdate.background);
    }
  }, [itemToUpdate]);

  const backGroungDrop = (index) => {
    if (acceptedFiles[index]) {
      return `url(${URL.createObjectURL(acceptedFiles[index])})`;
    } else if (itemToUpdate !== null) {
      return `url(/images/membres/${itemToUpdate.background})`;
    } else {
      return '';
    }
  };
  const handleLanguageChange = (e, language) => {
    e.preventDefault();
    const newValues = {
      ...textAreaValues,
      [currentLanguage]: textAreaRef.current.value
    };
    setTextAreaValues(newValues);
    setCurrentLanguage(language);
    textAreaRef.current.value = textAreaValues[language];
  };
  const handleTraduction = async (e) => {
    e.preventDefault();
    const text = textAreaRef.current.value;
    const result = await translateText({ text, languageTarget: currentLanguage });
    if (result.error) {
      window.alert(result.error);
    } else {
      textAreaRef.current.value = result.translation;
    }
  };

  // adding a infocontact block
  const handleAddContactInput = () => {
    const newInfoContact = {
      icons: [...infoDuContact.icons, ''],
      links: [...infoDuContact.links, '']
    };
    setInfoDuContact(newInfoContact);
  };
  const handleDeleteContactInput = (index) => {
    const newInfoContact = {
      ...infoDuContact,
      icons: infoDuContact.icons.filter((_, i) => i !== index),
      links: infoDuContact.links.filter((_, i) => i !== index)
    };
    setInfoDuContact(newInfoContact);
  };
  // handle the input to the contact's icons array
  const handleIconChange = (index, value) => {
    setInfoDuContact(prevInfo => ({
      icons: prevInfo.icons.map((icon, i) => (i === index ? value : icon)),
      links: prevInfo.links
    }));
  };
  // handle the input to the contact's links array
  const handleLinkChange = (index, value) => {
    setInfoDuContact(prevInfo => ({
      icons: prevInfo.icons,
      links: prevInfo.links.map((link, i) => (i === index ? value : link))
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formValid = true;
    let message = '';
    const formData = new FormData(e.target);

    // setting the description current values
    const currentTextAreaValues = {
      ...textAreaValues,
      [currentLanguage]: textAreaRef.current.value
    };
    setTextAreaValues(currentTextAreaValues);
    // verificando que todos los campos de description esten completos
    for (const [key, value] of Object.entries(currentTextAreaValues)) {
      if (value === '') {
        message += ` \nRemplizez la description: ${key}`;
        formValid = false;
        textAreaRef.current.classList.add('invalid_field');
      }
    }
    const descriptionData = JSON.stringify(currentTextAreaValues);
    formData.set('description', descriptionData);

    // checking the image and setting it in the formData
    if (acceptedFiles[0] || prevImage !== '') {
      if (!itemToUpdate) {
        formData.append('image', acceptedFiles[0]);
      } else if (itemToUpdate && acceptedFiles[0]) {
        formData.append('image', acceptedFiles[0]);
        formData.set('oldImage', prevImage);
      } else if (itemToUpdate && !acceptedFiles[0]) {
        formData.set('oldImage', prevImage);
      }
    } else {
      formValid = false;
      message += ' \nIl faut une photo pour le collegue';
    }
    // este bucle varifica si todos los INPUT del form estan completos
    const formInputs = e.target.elements;
    for (const [key, element] of Object.entries(formInputs)) {
      if (element.tagName === 'INPUT' && element.type !== 'file') {
        if (element.value === '') {
          element.classList.add('invalid_field');
          formValid = false;
          message = 'Completez tous les champs!';
        } else {
          element.classList.remove('invalid_field');
        }
      }
    }
    if (formValid) {
      // setting the contact info data
      const infoContactData = JSON.stringify(infoDuContact);
      formData.set('contact', infoContactData);
      if (!itemToUpdate) {
        try {
          const response = await insertMembreEquipe(formData);
          if (response.error) {
            window.alert(response.error);
            console.log(response);
          } else {
            window.alert('Membre ajoute!');
            window.location.reload();
          }
        } catch (error) {
          console.error(error);
          window.alert('Error al agregar al miembro al equipo. Por favor, inténtalo de nuevo más tarde.');
        }
      } else {
        // console.log(...formData);
        formData.set('membreId', itemToUpdate.id);
        try {
          const response = await updateMembreEquipe(formData);
          if (response.error) {
            window.alert('response.error');
            console.log(response);
          } else {
            window.alert('Membre mis a jour!');
            window.location.reload();
          }
        } catch (error) {

        }
      }
    } else {
      window.alert(message);
    }
  };

  return (
		<div>
			<h3>{ itemToUpdate != null ? 'Actualizer membre du equipe' : 'Nouvel membre du equipe' }</h3>
			<form className='admin_form form_insert_spectacle' onSubmit={handleSubmit}>
				<div className='insert_spectacle_left_block'>

			<div
					{...getRootProps()}
					className='form_drop_box'
					style={{
					  backgroundColor: isDragActive ? '#3e3e3ec7' : 'none',
					  backgroundImage: backGroungDrop(0),
					  outline: acceptedFiles[0] ? 'none' : 'white 2px dashed'
					}}>
      <input {...getInputProps()} />
      {
				isDragActive
				  ? <p>{acceptedFiles[0] ? '' : 'Suelta la imagen aqui...'} </p>
				  : <p>{acceptedFiles[0] ? '' : 'Arrastra aqui la imagen o haz click aqui...'}</p>
      }
        </div>
				<label>
					Nom *
					<input name='name' type='text' placeholder='Nom du membre' defaultValue={itemToUpdate ? itemToUpdate.name : ''} />
				</label>
				<label>
					Prenom *
					<input name='lastname' type='text' placeholder='Prenom du membre' defaultValue={itemToUpdate ? itemToUpdate.lastname : ''} />
				</label>
				<label>
					Role *
					<input name='role' type='text' placeholder='Role du membre' defaultValue={itemToUpdate ? itemToUpdate.role : ''} />
				</label>
        <label>
      Informations du contact (optionnele)
            {
              infoDuContact.links.length > 0 && (
                infoDuContact.links.map((_, index) => (
                  <div key={`contactBlock${index}`}>
                    <select value={infoDuContact.icons[index]} onChange={(e) => handleIconChange(index, e.target.value)}>
                      <option value={'IconFacebook'} > Facebook </option>
                      <option value={'IconInstagram'} >Instagram </option>
                      <option value={'IconYoutube'} > Youtube </option>
                      <option value={'IconLinkedin'} > Linkedin </option>
                    </select>
                    <input onChange={(e) => handleLinkChange(index, e.target.value)} type='text' placeholder={`link ${index} du contact`} value={infoDuContact.links[index]} />
                    <DeleteOutlineOutlinedIcon onClick={() => handleDeleteContactInput(index)} className='info_block_btns' />
                  </div>
                ))
              )
      }
    </label>
    <AddOutlinedIcon className='insert_spectacle_insert_info_btn' onClick={() => handleAddContactInput()} />
				</div>
				<div className='insert_spectacle_right_block'>
				  <label className='admin_description_btns'>
				  	Description *
				  </label>
          <div className='admin_description_bts'>
				    <button onClick={(e) => handleLanguageChange(e, 'fr')}>French</button>
            <button onClick={(e) => handleLanguageChange(e, 'esp')}>Spanish</button>
            <button onClick={(e) => handleLanguageChange(e, 'en')}>English</button>
            <button onClick={(e) => handleTraduction(e)}>Traducir</button>
          </div>
					<TextArea ref={textAreaRef} type='text' placeholder={`Description: ${currentLanguage}`} defaultValue={itemToUpdate ? itemToUpdate.name : ''} minHeight={'300px'} />
		      <button>Confirmer</button>
					<button onClick={handleBackdrop} >Annuler</button>
				</div>
			</form>
		</div>
  );
}

export default AdminFormInsertMembre;
