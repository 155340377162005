/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { motion, scroll } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useGetData } from '../hooks/useGetData';

import Collaborateur from '../components/Collaborateur';
import Description from '../components/Description';
import MainLayaut from '../components/MainLayaut';
import MembreEquipeCard from '../components/MembreEquipeCard';

import slideBtn from '../assets/icons/slide-btn.svg';

function LaCompagnie() {
  const background = `images/fonds/LaCompagnie.webp`;
  const { data: membres } = useGetData({ column: 'membres' });
  const { data: collaborateurs } = useGetData({ column: 'collaborateurs' });
  const [t] = useTranslation('pages');
  const handlePageUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [scrollOpacity, setScrollOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const opacity = Math.min(scrollPosition / 500, 1);
      setScrollOpacity(opacity);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <MainLayaut
      background={background}
      meta={{
        title: 'Acquaforte Qui Sommes Nous',
        description:
          "Explorez l'univers captivant d'Acquaforte Theatre à Montpellier et découvrez notre équipe passionnée d'artistes talentueux. Plongez dans l'histoire et la mission de notre compagnie de théâtre, et rencontrez les membres qui donnent vie à nos spectacles inoubliables. Joignez-vous à nous pour une expérience théâtrale riche en émotions et en créativité.",
      }}
    >
      <Description className="container_cards" opacity={scrollOpacity} />
      <section className="text-center ">
        <h2 className="mb-5">{t('/LaCompagnie.h2-first')}</h2>
        <section className="container_cards min-h-[400px]">
          {membres ? (
            membres.map((membre, index) => (
              <MembreEquipeCard membre={membre} key={membre.id} delay={index} />
            ))
          ) : (
            <div>Aucune membre dans l’équipe pour le moment</div>
          )}
        </section>
        <h2 className="mb-5">{t('/LaCompagnie.h2-second')}</h2>
        <section className="container_cards min-h-[400px]">
          {collaborateurs ? (
            collaborateurs.map((collaborateur) => (
              <Collaborateur
                collaborateur={collaborateur}
                key={collaborateur.id}
              />
            ))
          ) : (
            <p>Aucune collaborateur pour le moment</p>
          )}
        </section>
      </section>
      <img
        onClick={() => handlePageUp()}
        className="btn_slide"
        src={slideBtn}
        alt="bouton fermer slide"
      />
    </MainLayaut>
  );
}

export default LaCompagnie;
