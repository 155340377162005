/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
const Backrop = ({ children, onClick }) => {
  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        onClick();
      }
    });
    document.body.classList.add('no_scroll');
    return () => {
      // TODO: arreglar esto. esta el listener siempre activo...
      document.body.classList.remove('no_scroll');
    };
  }, []);

  const handleClick = (e) => {
    // Verificar si el clic proviene directamente del fondo
    if (e.target === e.currentTarget) {
      onClick();
    }
  };
  return (
    <motion.div
      className="backdrop"
      onClick={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default Backrop;
