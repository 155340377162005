import React from 'react';
import videoFond from '../assets/POPURRI DE ACQUA.mp4';
import MetaData from '../components/MetaData';

function Home() {
  const metaData = {
    title: 'Acquaforte Theatre Accueil',
    description:
      "Découvrez le théâtre à Montpellier avec Acquaforte Theatre. Explorez notre répertoire, achetez des billets et restez informé des dernières actualités théâtrales. Laissez-vous inspirer par l'art du théâtre.",
    image: 'default',
  };

  return (
    <>
      <MetaData
        title={metaData.title}
        description={metaData.description}
        image={metaData.image}
      />
      <section className="container_video">
        <video src={videoFond} autoPlay={true} loop={true} muted={true}></video>
      </section>
    </>
  );
}

export default Home;
