/* eslint-disable no-unused-vars */
import { motion } from 'framer-motion';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import logoWhite from '../assets/logo/logo-white.svg';
import { useGetData } from '../hooks/useGetData';
import './Description.css';

function Description({ opacity }) {
  const {
    data: [presentation],
    isLoading,
  } = useGetData({ column: 'presentation' });
  const [t, i18n] = useTranslation('pages');
  const currentLenguage = t(i18n.language);
  const slideRef = useRef(null);

  const descriptionFrameVariants = {
    hidden: {
      y: '-100%',
      transition: {
        duration: 0.5,
      },
    },
    visible: {
      y: '0',
      opacity: 1.5 - opacity,
      transition: {
        type: 'spring',
        duration: 0.7,
        // damping: 10,
        // stiffness: 150
      },
    },
  };
  return (
    <motion.section
      ref={slideRef}
      className="container_slide"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.509),
                    rgba(0, 0, 0, 1)),
                    url(/images/fonds/Description.webp)`,
      }}
      variants={descriptionFrameVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="flex flex-col justify-center items-center h-full">
        <img
          className="logo_description m-auto"
          src={logoWhite}
          alt="logo du la compagnie"
        />
        <h2 id="compagnie_titre">{t('/LaCompagnie.description.title')}</h2>
        {!presentation ? (
          <p> Loading... </p>
        ) : (
          <p id="compagnie_description"> {presentation[currentLenguage]}</p>
        )}
      </div>
    </motion.section>
  );
}

export default Description;
