/* eslint-disable no-unused-vars */
import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import Equipe from '../pages/LaCompagnie';
import Spectacles from '../pages/Spectacles';
import Description from './Description';
import Agenda from '../pages/Agenda';
import Contact from '../pages/Contact';
import Admin from '../pages/Admin';
import Conexion from '../pages/Conexion';
import ErrorPage from '../pages/ErrorPage';

function AnimatedRoutes () {
  const location = useLocation();
  // eslint-disable-next-line no-undef
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  return (
    <Routes location={location} key={location.pathname} >
        <Route path='/Admin' element={ isAuthenticated ? <Admin /> : <Navigate to="/Conexion" />} />
        <Route path='/Conexion' element={ !isAuthenticated ? <Conexion /> : <Navigate to="/Admin" />} />
        <Route index element={<Home />} />
        <Route path='/LaCompagnie' element={<Equipe />} />
        <Route path='/Description' element={<Description />} />
        <Route path='/Spectacles' element={<Spectacles />} />
        <Route path='/Agenda' element={<Agenda />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/ServerError' element={<ErrorPage error={'500'} />} />
        <Route path='*' element={<ErrorPage error={'404'} />} />
    </Routes>
  );
}

export default AnimatedRoutes;
