import './Footer.css';

import { useTranslation } from 'react-i18next';

import IconFacebook from '../assets/icons/icon-facebook.svg';
import IconInstagram from '../assets/icons/icon-instagram.svg';
import IconYoutube from '../assets/icons/icon-youtube.svg';

function Footer() {
  const [t] = useTranslation('global');

  return (
    <footer>
      <div className="social_icons flex gap-2">
        <a
          href="https://www.facebook.com/share/1GMVkTvhZa/?mibextid=LQQJ4d"
          target="_blank"
        >
          <img
            src={IconFacebook}
            alt="facebook-link"
            aria-label="lien facebook"
          />
        </a>
        <a
          href="https://www.instagram.com/acquafortetheatre?igsh=MWNrbmtjeWt2bzRiYQ=="
          target="_blank"
        >
          <img
            src={IconInstagram}
            alt="instagram-link"
            aria-label="lien instagram"
          />
        </a>
        <a
          href="https://www.youtube.com/@acquafortetheatre760/featured"
          target="_blank"
        >
          <img src={IconYoutube} alt="youtube-link" aria-label="lien youtube" />
        </a>
      </div>
      <div className="flex gap-10">
        <a id="foot_accueil" href="./" aria-label="accueil">
          {t('footer.link-accueil')}
        </a>
        <a id="foot_don" href="#" aria-label="lien pay pal, faire un don">
          {t('footer.link-don')}
        </a>
      </div>
    </footer>
  );
}

export default Footer;
