/* eslint-disable multiline-ternary */
/* eslint-disable space-before-function-paren */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useGetData } from '../hooks/useGetData';
import SpectacleCard from '../components/SpectacleCard';
import SpectacleBackdrop from '../components/SpectacleBackdrop';
import Backdrop from '../components/Backdrop';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainLayaut from '../components/MainLayaut';

function Spectacles() {
  const background = 'images/fonds/Spectacles.webp';
  const { data: spectacles } = useGetData({ column: 'spectacles' });
  const [t] = useTranslation('pages');
  const [spectacleOpen, setSpectacleOpen] = useState(false);
  const [spectacleSelected, setSpectacleSelected] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams({
    id: '',
    open: false,
    title: '',
    view: 'description',
  });
  const handleBackdrop = () => {
    console.log('pressed');
    // this function is empty to cancel the backdrop's behaviour and only closed it with the url manipulation
  };
  useEffect(() => {
    const id = searchParams.get('id');
    const isOpen = searchParams.get('open') === 'true';
    if (id && spectacles) {
      const selectedSpectacle = spectacles.find(
        (spectacle) => `${spectacle.id}` === id
      );
      setSpectacleSelected(selectedSpectacle);
    }
    setSpectacleOpen(isOpen);
  }, [searchParams, spectacles]);

  const handleOpenSpectacle = (spectacle) => {
    setSearchParams({
      open: true,
      title: spectacle.title,
      id: spectacle.id,
      view: 'description',
    });
  };
  return (
    <MainLayaut
      background={background}
      meta={{
        title: 'Acquaforte Spectacles',
        description:
          'Découvrez les prochains spectacles passionnants programmés par notre compagnie. Trouvez votre prochaine expérience culturelle ici.',
      }}
    >
      <section className="container_cards">
        {spectacles?.length > 0 ? (
          spectacles.map((spectacle, index) => (
            <SpectacleCard
              key={spectacle.id}
              spectacle={spectacle}
              delay={index}
              onClick={() => handleOpenSpectacle(spectacle)}
            />
          ))
        ) : (
          <p className="no_events_message">
            {t('/Spectacles.no-events-message')}
          </p>
        )}
        {spectacleOpen && spectacleSelected && (
          <Backdrop onClick={handleBackdrop}>
            <SpectacleBackdrop
              spectacle={spectacleSelected}
              handleBackdrop={handleBackdrop}
            />
          </Backdrop>
        )}
      </section>
    </MainLayaut>
  );
}

export default Spectacles;
