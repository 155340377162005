/* eslint-disable no-unused-vars */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGE_BASE_URL } from '../confing';
import { capitalize } from '../Utils/Helpers';
import Backdrop from './Backdrop';
import './MembreEquipeCard.css';

function MembreEquipeCard({ membre, delay }) {
  const { id, background, name, lastname, role, description } = membre;
  const [selectedCard, setSelectedCard] = useState(null);

  const [t, i18n] = useTranslation('global');
  const currentLanguage = t(i18n.language);

  const itemVariants = {
    hidden: { y: '100vh', opacity: 0 },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.08,
        type: 'spring',
        damping: 25,
        stiffness: 500,
        delay: delay / 10,
      },
    },
  };
  const cvModalVariants = {
    hidden: {
      y: '-100vh',
      rotate: 20,
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
    visible: {
      opacity: 1,
      y: '0',
      rotate: 0,
      transition: {
        duration: 0.1,
        type: 'spring',
        damping: 25,
        stiffness: 500,
        when: 'beforeChildren',
        staggerChildren: 0.3,
        delayChildren: -0.2,
      },
    },
    exit: {
      y: '-100vh',
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };
  const cvContentVariants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
  };

  return (
    <>
      <motion.div
        className="person_card"
        onClick={() => setSelectedCard(id)}
        variants={itemVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="block_person_card">
          <div
            className="person_card_image"
            alt={`image du membre ${name}`}
            style={{
              backgroundImage: `url(${IMAGE_BASE_URL}/images/membres/${background})`,
            }}
          ></div>
        </div>
        <div className="person_card_text">
          <h3>
            {capitalize(name)} {capitalize(lastname)}
          </h3>
          <p>{description[`vignette_${currentLanguage}`]}</p>
        </div>
      </motion.div>
      <AnimatePresence>
        {selectedCard && (
          <Backdrop onClick={() => setSelectedCard(null)}>
            <motion.div
              className="CV_open"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={cvModalVariants}
            >
              <motion.span
                onClick={() => setSelectedCard(null)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 8 }}
              >
                <CloseOutlinedIcon />
              </motion.span>
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={cvModalVariants}
                className="image_CV_open"
                alt={`image du membre ${name}`}
                style={{
                  backgroundImage: `url(${IMAGE_BASE_URL}/images/membres/${background})`,
                }}
              ></motion.div>
              <motion.div className="text_CV_open">
                <motion.h2 variants={cvContentVariants}>
                  {capitalize(name)} {capitalize(lastname)}
                </motion.h2>
                <motion.p variants={cvContentVariants}>
                  {capitalize(role)}
                </motion.p>
                <motion.p variants={cvContentVariants}>
                  {capitalize(description[currentLanguage])}
                </motion.p>
              </motion.div>
            </motion.div>
          </Backdrop>
        )}
      </AnimatePresence>
    </>
  );
}

export default MembreEquipeCard;
