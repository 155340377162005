/* eslint-disable no-unused-vars */
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import { motion } from 'framer-motion';
import React from 'react';
import { IMAGE_BASE_URL } from '../confing';
import { capitalize, parseDate } from '../Utils/Helpers';
import './Agenda.css';

const EventCard = ({ event, delay, onClick }) => {
  const itemVariants = {
    hidden: { y: '100vh', opacity: 0 },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.08,
        type: 'spring',
        damping: 25,
        stiffness: 500,
        delay: delay / 10,
      },
    },
  };

  const { dateFormat, heuresFormat } = parseDate(event.date);

  return (
    <motion.article
      className="new_event"
      variants={itemVariants}
      initial="hidden"
      animate="visible"
    >
      <div
        className="event_card_image"
        style={{
          backgroundImage: `url(${IMAGE_BASE_URL}/images/miniatures/${event.background})`,
        }}
      >
        {event.background && (
          <motion.img
            src={`/${IMAGE_BASE_URL}/images/agenda/${event.background}`}
            loading="lazy"
            alt={`Afiche du évènement ${event.title}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 2 } }}
          />
        )}
      </div>

      <div className="text-start px-2 border-b border-black flex justify-between items-center">
        <h3 className="font-semibold text-2xl ">{capitalize(event.title)}</h3>
        {event.id_spectacle && (
          <InfoOutlinedIcon onClick={onClick} style={{ cursor: 'pointer' }} />
        )}
      </div>
      <div className="flex flex-col text-start p-2 font-[1.2rem] gap-1">
        <div className="flex gap-3">
          <div className="flex gap-2">
            <CalendarMonthIcon className="p_events_cards" />
            {dateFormat}
          </div>
          <div className="flex gap-2">
            <AccessTimeIcon className="p_events_cards" />
            {heuresFormat}
          </div>
        </div>
        <a
          href={event.google_link}
          target="_blank"
          rel="noopener noreferrer"
          className="adresse_link"
          aria-label={`Lien adresse Google Maps pour le spectacle ${event.title}`}
        >
          <span className="flex gap-2">
            <PlaceIcon className="p_events_cards" />
            {capitalize(event.place)}
          </span>
        </a>
        <p className="pl-1 text-lg">{event.adresse}</p>
      </div>
    </motion.article>
  );
};

export default EventCard;
